/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { ActionCard, COLORS, FontWeight, Icon, Text } from '@clutter/clean';
import { useTrack } from '@clutter/wt';
import styled from '@emotion/styled';

import { BillingTabs } from '@portal/components/billing/tabs';
import { BuildSourceDialog } from '@portal/components/sources/build_source_dialog';
import { billingURL, orderMovingAddressURL, orderURL, planAddURL, planUpdateURL } from '@portal/config/routes';
import { PAYMENT_SOURCE_REQUIRED } from '@portal/config/source';
import { Spacer } from '@shared/components/helpers/spacer';

import { Moving__PackingEnum, OrderSubtypeEnum, OrderTypeEnum, PackageKindEnum } from '@portal/schema';
import { AppointmentDetailsOrder } from './appointment_details/full_panel';
import { CuftDiscrepancyAlert } from './cuft_discrepancy_alert';
import { DisposalAttachModal } from './disposal_attach_modal';

const TaskActionCard = styled(ActionCard)`
  margin: 0 0 24px;
`;

const INITIAL = 'Add';
const UPDATE_TEXT = 'Update';

const Container = styled.div`
  margin-bottom: 40px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px 10px;
`;

const ActionLink = styled(Text.Callout)`
  font-weight: ${FontWeight.Medium};
  color: ${COLORS.tealPrimary};

  &:hover {
    color: ${COLORS.__primaryButtonHover};
  }
`;

const UpdateContainer: React.FC = () => <ActionLink>{UPDATE_TEXT}</ActionLink>;

export const TaskList: React.FC<{
  order: AppointmentDetailsOrder;
  inEstimationFlowTest: boolean;
  estimationFlowCompleted: boolean;
  fitsPlanSize?: boolean;
}> = ({ order, inEstimationFlowTest, estimationFlowCompleted, fitsPlanSize }) => {
  const history = useHistory();
  const [showCardModal, setShowCardModal] = useState<boolean>(false);
  const [showDisposalModal, setShowDisposalModal] = useState<boolean>(false);

  const addedStorage = !!order.simultaneousPickup;

  const trackClick = useTrack({
    pageName: 'portal:appointment_details',
    container: 'task_list',
    action: 'click',
    objectType: 'card',
  });

  const onAddSourceSelect = (orderID: string) => {
    trackClick({
      objectName: 'add_source',
      label: 'Add Credit Card',
      value: 'credit_card',
      order_id: orderID,
    });
    setShowCardModal(true);
  };

  const onPackingMaterialsSelect = () => {
    const url = orderURL(order.id, 'packing_bundles');
    trackClick({
      objectName: 'packing_materials',
      label: UPDATE_TEXT,
      value: url,
      order_id: order.id,
    });
    history.push(url);
  };

  const onUpdatePlanSelect = () => {
    const url = planUpdateURL();
    trackClick({
      objectName: 'update_plan',
      value: url,
    });
    history.push(url);
  };

  const onAddStorageSelect = () => {
    const url = planAddURL(order.id);
    trackClick({
      objectName: 'add_storage',
      value: url,
    });
    history.push(url);
  };

  const onUpdateBillingSelect = (orderID: string) => {
    const url = billingURL(BillingTabs.Payment);
    trackClick({
      objectName: 'update_source',
      label: UPDATE_TEXT,
      value: url,
      order_id: orderID,
    });
    history.push(url);
  };

  const onUpdateAddressSelect = (orderID: string, label: string, isMovingOrder: boolean) => {
    const url = isMovingOrder ? `${orderMovingAddressURL(orderID)}?kind=origin` : orderURL(orderID, 'address');
    trackClick({
      objectName: 'update_address',
      label: label,
      value: url,
      order_id: orderID,
    });
    history.push(url);
  };

  const onCOIRequestSelect = (orderID: string, label: string) => {
    const url = orderURL(orderID, 'coi');
    trackClick({
      objectName: 'request_coi',
      label: label,
      value: url,
      order_id: orderID,
    });
    history.push(url);
  };

  const onUpdateItemEstimatesSelect = (orderID: string, label: string) => {
    const url = orderURL(orderID, inEstimationFlowTest ? 'estimation_flow' : 'estimated_items');
    trackClick({
      objectName: 'update_item_estimates',
      label: label,
      value: url,
      order_id: orderID,
    });
    history.push(url);
  };

  const isMovingOrder = order.type === OrderTypeEnum.Move;
  const disposalService = order.services.find((s) => s.type === OrderTypeEnum.Disposal);
  const hasDisposal = !!disposalService;
  const canAddDisposal = order.permissions.addDisposal;
  const addCreditCardTaskCompleted = !PAYMENT_SOURCE_REQUIRED;
  const packingMaterialsAdded = !!order.accountPackages.find(({ kind }) => kind === PackageKindEnum.PackingSupplies);
  const noPackingHelp = order.movingOperation?.packing === Moving__PackingEnum.EverythingIsPacked;
  // buildingRestrictionsAnswer is a required field when editing and will always
  // have an answer when the address details are updated
  const homeTaskCompleted = !!order.address?.details?.buildingRestrictionsAnswer;
  // requestedMovers is a required field when editing and will always
  // have an answer when the estimations are updated in portal but not in post-booking
  const virtualWalkthroughCompleted = estimationFlowCompleted;

  return (
    <>
      <Container>
        <Text.Title size="large">How to prepare</Text.Title>
        <Spacer height="24px" />
        {order.subtype === OrderSubtypeEnum.Onboarding && estimationFlowCompleted && !fitsPlanSize && (
          <CuftDiscrepancyAlert orderID={order.id} />
        )}
        <Spacer height="24px" />
        {!order.bookingPartner && !isMovingOrder && (
          <TaskActionCard
            selected={addCreditCardTaskCompleted}
            onSelect={() =>
              addCreditCardTaskCompleted ? onUpdateBillingSelect(order.id) : onAddSourceSelect(order.id)
            }
            icon={addCreditCardTaskCompleted ? Icon.Check : Icon.Arrow}
            iconPosition="center"
          >
            <TextContainer>
              <div>
                <Text.Title size="extraSmall">Add your credit card</Text.Title>
                <Text.Callout>Confirm your appointment by adding your credit card information.</Text.Callout>
              </div>
              {addCreditCardTaskCompleted && <UpdateContainer />}
            </TextContainer>
          </TaskActionCard>
        )}
        <TaskActionCard
          selected={virtualWalkthroughCompleted}
          onSelect={() =>
            virtualWalkthroughCompleted
              ? onUpdateItemEstimatesSelect(order.id, UPDATE_TEXT)
              : onUpdateItemEstimatesSelect(order.id, INITIAL)
          }
          icon={virtualWalkthroughCompleted ? Icon.Check : Icon.Arrow}
          iconPosition="center"
        >
          <TextContainer>
            <div>
              <Text.Title size="extraSmall">Tell us what you’re moving</Text.Title>
              <Text.Callout>
                Complete a virtual walkthrough of your items so that our team can come prepared.
              </Text.Callout>
            </div>
            {virtualWalkthroughCompleted && <UpdateContainer />}
          </TextContainer>
        </TaskActionCard>
        <TaskActionCard
          selected={homeTaskCompleted}
          onSelect={() =>
            homeTaskCompleted
              ? onUpdateAddressSelect(order.id, UPDATE_TEXT, isMovingOrder)
              : onUpdateAddressSelect(order.id, INITIAL, isMovingOrder)
          }
          icon={homeTaskCompleted ? Icon.Check : Icon.Arrow}
          iconPosition="center"
        >
          <TextContainer>
            <div>
              <Text.Title size="extraSmall">Tell us about your home</Text.Title>
              <Text.Callout>Answer a few questions about your home so our team can come prepared.</Text.Callout>
            </div>
            {homeTaskCompleted && <UpdateContainer />}
          </TextContainer>
        </TaskActionCard>
        {!order.bookingPartner && isMovingOrder && !order.permissions.whiteGloveTestEligible && (
          <TaskActionCard
            selected={noPackingHelp || packingMaterialsAdded}
            onSelect={onPackingMaterialsSelect}
            icon={noPackingHelp || packingMaterialsAdded ? Icon.Check : Icon.Arrow}
            iconPosition="center"
          >
            <TextContainer>
              <div>
                <Text.Title size="extraSmall">Add packing and supplies</Text.Title>
                <Text.Callout>Find a moving box kit that works best for your moving needs.</Text.Callout>
              </div>
              {(noPackingHelp || packingMaterialsAdded) && <UpdateContainer />}
            </TextContainer>
          </TaskActionCard>
        )}
        {!order.bookingPartner && isMovingOrder && (addedStorage || order.storageAddOnEligible) && (
          <TaskActionCard
            selected={addedStorage}
            onSelect={addedStorage ? onUpdatePlanSelect : onAddStorageSelect}
            icon={addedStorage ? Icon.Check : Icon.Arrow}
            iconPosition="center"
          >
            <TextContainer>
              <div>
                <Text.Title size="extraSmall">
                  {addedStorage ? 'Update your storage plan' : 'Add a storage plan'}
                </Text.Title>
                <Text.Callout>Units range from 5x5 to 10x50 and no minimum commitments</Text.Callout>
              </div>
              {addedStorage && <UpdateContainer />}
            </TextContainer>
          </TaskActionCard>
        )}
        <TaskActionCard onSelect={() => onCOIRequestSelect(order.id, INITIAL)} icon={Icon.Arrow} iconPosition="center">
          <TextContainer>
            <div>
              <Text.Title size="extraSmall">Request a COI</Text.Title>
              <Text.Callout>
                Once you fill out the requested materials, we will email a completed Certificate of Insurance (COI) to
                you and your building manager.
              </Text.Callout>
            </div>
          </TextContainer>
        </TaskActionCard>
        {canAddDisposal && (
          <TaskActionCard
            selected={hasDisposal}
            onSelect={
              ((e: React.MouseEvent | React.KeyboardEvent) => {
                if (e.currentTarget.contains(e.target as Node)) {
                  setShowDisposalModal(true);
                }
              }) as any
            }
            icon={hasDisposal ? Icon.Check : Icon.Arrow}
            iconPosition="center"
          >
            <TextContainer>
              <div>
                <Text.Title size="extraSmall">{hasDisposal ? 'Disposal added' : 'Add disposal service'}</Text.Title>
                <Text.Callout>
                  {hasDisposal ? (
                    <a href="#">Review disposal pricing ahead of your appointment.</a>
                  ) : (
                    <>
                      Clutter can dispose of unwanted items for you.{' '}
                      <a href="#">See pricing information and restrictions</a>
                    </>
                  )}
                </Text.Callout>
              </div>
            </TextContainer>
          </TaskActionCard>
        )}
      </Container>
      {showCardModal && (
        <BuildSourceDialog
          onClose={() => setShowCardModal(false)}
          onSave={() => {
            window.location.reload();
          }}
        />
      )}
      <DisposalAttachModal
        parentId={order.id}
        isOpen={showDisposalModal}
        onClose={() => setShowDisposalModal(false)}
        disposalId={disposalService?.id}
      />
    </>
  );
};

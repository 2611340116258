import React, { useState } from 'react';
import { Box, Button, COLORS, Modal, Text } from '@clutter/clean';
import { DisposalPricingSummary } from '@portal/components/orders/disposal_confirmation/pricing_modal';
import { RestrictedDisposalItems } from '@portal/components/orders/home/disposal_restrictions';
import { Tabs } from '@portal/components/helpers/tabs';
import {
  AppointmentHubDocument,
  OrderDetailsDocument,
  Status,
  useAttachDisposalMutation,
  useCancelOrderMutation,
} from '@portal/schema';

export function DisposalAttachModal({
  isOpen,
  parentId,
  disposalId,
  onClose,
}: {
  parentId: string;
  isOpen: boolean;
  disposalId?: string;
  onClose: () => void;
}) {
  const [tab, setTab] = useState<'pricing' | 'restrictions'>('pricing');
  const refetchQueries = [
    { query: AppointmentHubDocument, variables: { orderID: parentId } },
    { query: OrderDetailsDocument, variables: { orderID: parentId } },
  ];
  const [attach, { data: attachData, loading: attaching, error: attachError }] = useAttachDisposalMutation({
    variables: { parentId },
    refetchQueries: refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: ({ disposalAttach }) => {
      if (disposalAttach?.status === Status.Ok) {
        onClose();
      }
    },
  });

  const [cancel, { data: cancelData, loading: canceling, error: cancelError }] = useCancelOrderMutation({
    variables: { orderID: disposalId!, skipCancellationFee: true },
    refetchQueries: refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: ({ cancelOrder }) => {
      if (cancelOrder?.status === Status.Ok) {
        onClose();
      }
    },
  });

  const hasDisposal = !!disposalId;
  const loading = attaching || canceling;
  const error = attachError || cancelError;
  const hasUnprocessableStatus = [attachData?.disposalAttach?.status, cancelData?.cancelOrder?.status].includes(
    Status.Unprocessable,
  );

  return (
    <Modal isOpen={isOpen} handleModalClose={onClose} includeCloseButton>
      <Box.Flex
        padding="48px 24px 24px"
        minWidth="300px"
        maxHeight="90vh"
        width={['calc(100vw - 32px)', 'initial']}
        maxWidth="500px"
        gap="16px"
        flexDirection="column"
        overflow="auto"
      >
        <Box.Flex flexDirection="column" gap="8px">
          <Text.Title size="medium">{!hasDisposal && 'Add '}Disposal Service</Text.Title>
          <Box>
            Clutter can pick up additional items for disposal - you can let us know now or during your appointment.
            Please review pricing and restrictions below.
          </Box>
          <Box textAlign="center" margin="12px 0 0">
            {hasDisposal ? (
              <Button kind="destructive" disabled={loading} loading={loading} onClick={() => cancel()}>
                Cancel disposal service
              </Button>
            ) : (
              <Button disabled={loading} loading={loading} onClick={() => attach()}>
                Yes, I have items for disposal
              </Button>
            )}
          </Box>
          {(error || hasUnprocessableStatus) && (
            <Text.Body color={COLORS.toucan}>Something went wrong. Please try again.</Text.Body>
          )}
        </Box.Flex>
        <Tabs>
          <Tabs.Tab onClick={() => setTab('pricing')} active={tab === 'pricing'}>
            Pricing
          </Tabs.Tab>
          <Tabs.Tab onClick={() => setTab('restrictions')} active={tab === 'restrictions'}>
            Restrictions
          </Tabs.Tab>
        </Tabs>
        {tab === 'pricing' ? <DisposalPricingSummary /> : <RestrictedDisposalItems />}
      </Box.Flex>
    </Modal>
  );
}

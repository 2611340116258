import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  OrderServiceTypeEnum,
  OrderDetailsQueryVariables,
  DropOff__Appointment,
  OrderTypeEnum,
  OrderSubtypeEnum,
  useShipmentLabelsQuery,
  useUncancellableShipmentOrderIDsQuery,
} from '@portal/schema';

import { ConfigWithSetup } from '@shared/components/inventory_capture';

import { Header } from '@portal/components/helpers/header';
import { buildOrderInventory } from '@portal/utils';
import trashCan from '@portal/components/shared/icons/trash_can_large.svg';

import { ActionCard, Box, FontWeight, Icon, SpinDotsLoader, Text } from '@clutter/clean';
import styled from '@emotion/styled';

import { IInventoryConfig, IAccount, IOrder, OrderStatus } from '@shared/types';
import { Address } from './details/address';
import { Alerts } from './details/alerts';
import { Cancel } from './details/cancel';
import { Contact } from './details/contact';
import { Inventory } from './details/inventory';
import { Plan } from './details/plan';
import { Scheduled } from './details/scheduled';
import { Documents } from './documents';
import { Scheduled as DisposalScheduled } from './details/disposal/scheduled';
import { Scheduled as DropOffScheduled } from './details/drop_off/scheduled';
import { AddressView as DropOffAddress } from './details/drop_off/address_view';
import { Scheduled as FacilityScheduled } from './details/facility/scheduled';
import { AddressView as FacilityAddressView } from './details/facility/address_view';
import { Contact as FacilityContact } from './details/facility/contact';
import { Scheduled as ShipmentScheduled } from './details/shipping/scheduled';
import { AddressView as ShipmentAddress } from './details/shipping/address_view';
import { Contact as ShipmentContact } from './details/shipping/contact';
import { Tracking as ShipmentTracking } from './details/shipping/tracking';
import { PrintLabels as ShipmentPrintLabels } from './details/shipping/print_labels';

import { PerItemPricingCosts } from './per_item_pricing_costs';
import { DoorstepNotice } from './details/doorstep_notice';
import * as QUERY from './details.gql';
import { Home } from './home';
import { LaborCostDetails } from './labor_cost_details';
import { WaitlistRequests } from './details/waitlist_requests';
import { DisposalAttachModal } from './home/disposal_attach_modal';

const LoaderContainer = styled(Box)`
  width: 100%;
  text-align: center;
`;

interface IQueryData extends IInventoryConfig {
  account: IAccount;
  order: IOrder;
}

type DropOffDetailsProps = {
  account: IAccount;
  order: IOrder;
  dropOffAppointment: DropOff__Appointment;
  config: IInventoryConfig;
  onboarding: boolean;
};

const DropOffDetails: React.FC<DropOffDetailsProps> = ({ account, order, dropOffAppointment, config, onboarding }) => (
  <>
    <DropOffScheduled order={order} dropOffAppointment={dropOffAppointment} />

    {onboarding && <Plan order={order} />}

    <DropOffAddress fulfiller={dropOffAppointment.facility.fulfiller} address={dropOffAppointment.facility.address} />

    <Contact account={account} order={order} />

    {order.services.map((service, key) => (
      <ConfigWithSetup key={key} {...config} inventory={buildOrderInventory(service, config)}>
        <Inventory order={order} service={service} />
      </ConfigWithSetup>
    ))}

    {order.perItemPricingInfo && (
      <>
        <Header tag="h4">Pricing Information</Header>
        <PerItemPricingCosts pricingInfo={order.perItemPricingInfo} />
        <hr />
      </>
    )}

    <Documents orderID={order.id} />

    <Cancel order={order} />
  </>
);

const ShipmentDetails: React.FC<{
  account: IAccount;
  order: IOrder;
  config: IInventoryConfig;
}> = ({ account, order, config }) => {
  const { data, loading } = useShipmentLabelsQuery({ variables: { orderId: order.id } });
  const { data: uncancellableData, loading: uncancellableLoading } = useUncancellableShipmentOrderIDsQuery({
    variables: { orderIDs: [order.id] },
  });

  if (loading || uncancellableLoading || !data || !uncancellableData) {
    return (
      <LoaderContainer>
        <SpinDotsLoader />
      </LoaderContainer>
    );
  }

  const labels = data.shipmentLabels;
  const uncancellable = uncancellableData.uncancellableShipmentOrderIds.length > 0;

  if (order.type === OrderTypeEnum.Return) {
    return (
      <>
        <ShipmentScheduled order={order} />

        <ShipmentAddress address={{ address: order.address }} type={order.type} />

        <ShipmentContact account={account} order={order} />

        {order.services.map((service, key) => (
          <>
            <ShipmentTracking service={service} />
            <ConfigWithSetup key={key} {...config} inventory={buildOrderInventory(service, config)}>
              <Inventory order={order} service={service} />
            </ConfigWithSetup>
          </>
        ))}

        <Documents orderID={order.id} />

        <Cancel order={order} />
      </>
    );
  } else {
    return (
      <>
        <ShipmentPrintLabels order={order} labels={labels} />

        <ShipmentAddress address={{ address: order.address }} type={order.type} />

        <ShipmentContact account={account} order={order} />

        <Documents orderID={order.id} />

        {!uncancellable && <Cancel order={order} />}
      </>
    );
  }
};

const FacilityOrderDetails: React.FC<{
  account: IAccount;
  order: IOrder;
  config: IInventoryConfig;
}> = ({ account, order, config }) => (
  <>
    <FacilityScheduled order={order} />
    <FacilityAddressView order={order} />
    <FacilityContact account={account} order={order} />

    {order.services.map((service, key) => (
      <ConfigWithSetup key={key} {...config} inventory={buildOrderInventory(service, config)}>
        <Inventory order={order} service={service} />
      </ConfigWithSetup>
    ))}

    <Documents orderID={order.id} />
    <Cancel order={order} />
  </>
);

const DisposalOrderDetails: React.FC<{
  order: IOrder;
  config: IInventoryConfig;
}> = ({ order, config }) => (
  <>
    <DisposalScheduled order={order} />

    {order.services.map((service, key) => (
      <ConfigWithSetup key={key} {...config} inventory={buildOrderInventory(service, config)}>
        <Inventory order={order} service={service} />
      </ConfigWithSetup>
    ))}

    <Documents orderID={order.id} />
    <Cancel order={order} />
  </>
);

const ServiceAddOnDetails: React.FC<{ order: IOrder }> = ({ order }) => {
  const [showDisposalModal, setShowDisposalModal] = React.useState<boolean>(false);

  const disposalService = order.services.find((s) => s.type === OrderTypeEnum.Disposal);

  return (
    <>
      <Box.Grid gridGap={['12px', '16px']} gridTemplateColumns={['1fr', '1fr 1fr']} padding="12px 0">
        <ActionCard
          icon={disposalService ? Icon.RadioChecked : Icon.RadioEmpty}
          iconPosition="center"
          onSelect={() => setShowDisposalModal(true)}
        >
          <Box.Flex justifyContent="space-between" gap="12px" minHeight="80px">
            <Box.FlexItem margin="0 0 12px">
              <Text.Body weight={FontWeight.Medium}>Disposal</Text.Body>
              <Text.Callout>
                {disposalService ? (
                  <a href="#">Review disposal pricing ahead of your appointment.</a>
                ) : (
                  <>
                    Clutter can dispose of unwanted items for you.{' '}
                    <a href="#">See pricing information and restrictions</a>
                  </>
                )}
              </Text.Callout>
            </Box.FlexItem>
            <Box.FlexItem alignSelf="center">
              <img src={trashCan} height={60} width={42} draggable={false} alt="disposal" />
            </Box.FlexItem>
          </Box.Flex>
        </ActionCard>
      </Box.Grid>
      {showDisposalModal && (
        <DisposalAttachModal
          parentId={order.id}
          isOpen={showDisposalModal}
          disposalId={disposalService?.id}
          onClose={() => setShowDisposalModal(false)}
        />
      )}
    </>
  );
};

export const Details: React.FC<RouteComponentProps<{ orderID: string }>> = (props) => {
  const {
    match: {
      params: { orderID },
    },
  } = props;
  const { data } = useQuery<IQueryData, OrderDetailsQueryVariables>(QUERY, {
    variables: { orderID },
    fetchPolicy: 'network-only',
  });

  if (!data || !data.account || !data.order) {
    return null;
  }

  const { account, order, ...config } = data;
  const { dropOffAppointment } = order;
  const onboarding = order.type === OrderTypeEnum.Pickup && order.subtype === OrderSubtypeEnum.Onboarding;
  const move = order.type === OrderTypeEnum.Move;
  const supplyKit = order.type === OrderTypeEnum.RetailDelivery && order.subtype === OrderSubtypeEnum.SupplyKit;
  const shipment = order.serviceType === OrderServiceTypeEnum.Shipment;
  const facility = order.serviceType === OrderServiceTypeEnum.Facility;
  const disposal = order.serviceType === OrderServiceTypeEnum.Disposal || order.type === OrderTypeEnum.Disposal;
  const pending = order.status === OrderStatus.Pending;
  const scheduled = order.status === OrderStatus.Scheduled;
  const onWaitlist = pending || scheduled;
  const canAddDisposal = order.permissions.addDisposal;

  if (onboarding || move || supplyKit) {
    return <Home {...props} />;
  }

  if (dropOffAppointment) {
    return (
      <DropOffDetails
        account={account}
        order={order}
        dropOffAppointment={dropOffAppointment}
        config={config}
        onboarding={onboarding}
      />
    );
  }

  if (shipment) {
    return <ShipmentDetails account={account} order={order} config={config} />;
  }

  if (facility) {
    return <FacilityOrderDetails account={account} order={order} config={config} />;
  }

  if (disposal) {
    return <DisposalOrderDetails order={order} config={config} />;
  }

  return (
    <>
      <Alerts config={config} order={order} />
      {!pending && <Scheduled order={order} />}
      {onWaitlist && <WaitlistRequests order={order} isFirstEntry={pending} />}
      {order.serviceType === OrderServiceTypeEnum.Doorstep && <DoorstepNotice />}
      {onboarding && <Plan order={order} />}
      <Address order={order} />
      <Contact account={account} order={order} />
      {order.services.map((service, key) => (
        <ConfigWithSetup key={key} {...config} inventory={buildOrderInventory(service, config)}>
          <Inventory order={order} service={service} />
        </ConfigWithSetup>
      ))}
      {order.perItemPricingInfo && (
        <>
          <Header tag="h4">Pricing Information</Header>
          <PerItemPricingCosts pricingInfo={order.perItemPricingInfo} />
          <hr />
        </>
      )}
      {!order.perItemPricingInfo && order.laborCostDetails && (
        <>
          <Header tag="h4">Pricing Information</Header>
          <LaborCostDetails laborCostDetails={order.laborCostDetails} />
          {order.coupon && (
            <div>
              <b>Coupon:</b> {order.coupon.discountDescription}
            </div>
          )}
          <hr />
        </>
      )}
      {order.type === OrderTypeEnum.Pickup && canAddDisposal && (
        <>
          <Header tag="h4">Optional Service Add-ons</Header>
          <ServiceAddOnDetails order={order} />
          <hr />
        </>
      )}
      <Documents orderID={order.id} />
      <Cancel order={order} />
    </>
  );
};
